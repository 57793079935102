<template>
<div>
<section>
        <base-card>
            <h2>Register as a coache now!</h2>
            <coache-form @save-data="saveData"></coache-form>
        </base-card>
    </section>
</div>
</template>

<script>
import CoacheForm from '../../components/coaches/CoacheForm.vue'
import BaseCard from '../../components/ui/baseCard.vue'
export default {
    components:{CoacheForm, BaseCard},
   
    methods:{
        saveData(data){
            this.$store.dispatch('coaches/registerCoache', data);
            this.$router.replace('/coaches');
        }
    }
}
</script>