<template>
<div>
    <base-dialog :show="!!error" title="Error occured!" @close="handleError">
        <p>{{error}}</p>
    </base-dialog>
    <section>
        <base-card>
            <header>
                <h2>Requests Recived</h2>
            </header>
            <base-spinner v-if="isLoading"></base-spinner>
            <ul v-else-if="hasRequests && !isLoading">
                <requesr-item v-for="req in receivedRequests" 
                :key="req.id"
                :email="req.userEmail"
                :message="req.message">
                </requesr-item>
            </ul>
            <h3 v-else>You haven't recevied any requests yet!</h3>
        </base-card>
    </section>
</div>
</template>

<script>
import requesrItem from '../../components/requests/requestItem.vue'
import BaseDialog from '../../components/ui/BaseDialog.vue';
import BaseSpinner from '../../components/ui/BaseSpinner.vue';
export default {
    data(){
        return{
            isLoading: false,
            error: null
        };
    },
    components:{ requesrItem, BaseDialog, BaseSpinner },
    computed:{
        receivedRequests(){
            return this.$store.getters['requests/requests'];
        },
        hasRequests(){
            return this.$store.getters['requests/hasRequests'];
        }
    },
    methods:{
        async loadRequests(){
            this.isLoading = true;
            try{
                console.log("start...");
                await this.$store.dispatch("requests/fetchRequests");
            }catch(error){
                this.error = error.message || "Somthing failed!";
            }
            this.isLoading = false;
           
        },
        handleError(){
            this.error = null;
        }
    },
    created(){
        this.loadRequests();
    }
}
</script>

<style scoped>
header {
  text-align: center;
}

ul {
  list-style: none;
  margin: 2rem auto;
  padding: 0;
  max-width: 30rem;
}

h3 {
  text-align: center;
}
</style>