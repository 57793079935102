<template>
<div>
    <base-dialog :show="!!error" title="Error occured!" @close="handleError">
        <p>{{error}}</p>
    </base-dialog>
        <section>
            <coache-filter @change-filter="setFilters"></coache-filter>
        </section>
        <section>
            <base-card>
                <div class="controls">
                    <base-button @click="loadCoaches(true)" mode="outline">Refresh</base-button>
                    <base-button v-if="!isCoache && !isLoading" link to="/register">Register as a Coache</base-button>
                </div>
                <div v-if="isLoading">
                    <base-spinner>  </base-spinner>
                </div>
                <ul v-else-if="hasCoaches">
                <coache-item v-for="coach in filteredCoaches" :key="coach.id"
                :id="coach.id"
                :firstName="coach.firstName"
                :lastName="coach.lastName"
                :rate="coach.hourlyRate"
                :areas="coach.areas"
                ></coache-item>
                </ul>
                <h3 v-else>No Coaches Found!</h3>
            </base-card>
        </section>
   </div>
</template>

<script>
import CoacheFilter  from '../../components/coaches/coacheFilter.vue';
import CoacheItem from '../../components/coaches/coacheItem.vue';
import BaseDialog from '../../components/ui/BaseDialog.vue';
export default {
    components:{ CoacheItem, CoacheFilter, BaseDialog },
    computed:{
        filteredCoaches(){
            const coaches = this.$store.getters['coaches/coaches'];
            return coaches.filter(coache =>{
                if(this.activeFilters.frontend && coache.areas.includes('frontend')){
                    return true;
                }
                if(this.activeFilters.backend && coache.areas.includes('backend')){
                    return true;
                }
                if(this.activeFilters.career && coache.areas.includes('career')){
                    return true;
                }

                return false;
            })
        },
        isCoache(){
            return this.$store.getters['coaches/isCoache'];
        },
        hasCoaches(){
        return !this.isLoading && this.$store.getters['coaches/hasCoaches'];
        }
    },
    data(){
        return {
            activeFilters:{
                frontend: true,
                backend: true,
                career: true
            },
            isLoading: false,
            error: null
        }
    },
    methods:{
        setFilters(updatedFilters){
            this.activeFilters = updatedFilters;
        },
        async loadCoaches(refresh = false){
            this.isLoading = true;
            try{
                await this.$store.dispatch('coaches/loadCoaches',{forceRefresh: refresh});
                
            }catch(error){
                this.error = error.message;
            }
            this.isLoading = false;
        },
        handleError(){
            this.error = null;
        }
    },
    created(){
        this.loadCoaches();
    }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>