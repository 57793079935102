import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store/index.js'
import baseCard from './components/ui/baseCard.vue';
import baseButton from './components/ui/baseButton.vue';
import BaseBadge from './components/ui/baseBadge.vue';
import BaseSpinner from './components/ui/BaseSpinner.vue';
import BaseDialog from './components/ui/BaseDialog.vue';
const app = createApp(App);

app.component('base-card', baseCard);
app.component('base-button', baseButton);
app.component('base-badge', BaseBadge);
app.component('base-spinner', BaseSpinner);
app.component('base-dialog',BaseDialog);

app.use(store);
app.use(router);

app.mount('#app');
