import { createRouter, createWebHistory } from 'vue-router';

import CoacheDetail from './pages/coaches/coacheDetails.vue';
import CoacheRegistration from './pages/coaches/coacheRegistration.vue';
import CoachesList from './pages/coaches/coachesList.vue';
import ContactCoache from './pages/requests/contactCoache.vue';
import RequestsRecived from './pages/requests/requestsRecived.vue';
import UserAuth from './pages/auth/UserAuth.vue';
import NotFound from './pages/NotFound.vue';

const router = createRouter({
    history : createWebHistory(),
    routes: [
        {path: '/', redirect: "/coaches"},
        {path: '/coaches', component: CoachesList},
        {path: '/coaches/:id', 
        component: CoacheDetail,
        props: true, 
        children: [
            {path: 'contact', component: ContactCoache}
        ]},
        {path: '/register', component: CoacheRegistration},
        {path: '/requests', component: RequestsRecived},
        {path: '/auth', component: UserAuth},
        {path: '/:notFound(.*)', component: NotFound},
    ]
});

export default router;