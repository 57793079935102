export default { 
    async registerCoache(context, data){
        const userId = context.rootGetters.userId;
        const coacheData = {
            //id: context.rootGetters.userId,
            firstName: data.first,
            lastName: data.last,
            description: data.desc,
            hourlyRate: data.rate,
            areas: data.areas
        };

        const response = await fetch(`https://coach-vue-test-default-rtdb.firebaseio.com/coaches/${userId}.json`,{
           method: 'PUT',
           body: JSON.stringify(coacheData)
        });

        //const responseDate = await response.json();
        if(!response.ok){
            //error
        }

        context.commit('registerCoache', {
            ...coacheData,
            id: userId
        });
    },
    async loadCoaches(context, payload){
        if(!payload.forceRefresh && !context.getters.shouldUpdate){
            return;
        }
        
        const response = await fetch('https://coach-vue-test-default-rtdb.firebaseio.com/coaches.json');
        const responseDate =  await response.json();
        
        if(!response.ok){
            const error = new Error(responseDate.message || 'Failed to fetch data');
            throw error;
        }

        const coaches = [];

        for(const key in responseDate){
            const coach = {
                firstName: responseDate[key].firstName,
                lastName: responseDate[key].lastName,
                description: responseDate[key].description,
                hourlyRate: responseDate[key].hourlyRate,
                areas: responseDate[key].areas,
                id: key
            }
            coaches.push(coach);
        }
        context.commit('setCoaches', coaches);
        context.commit("setFetchTimestamp");
    }
}