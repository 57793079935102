<template>
<div>
    <section>
        <base-card>
            <h2>{{fullName}}</h2>
            <h3>{{selectedCoache.rate}}</h3>
        </base-card>
    </section>
    <section>
        <base-card>
            <header>
                <h2>Intrested? Reach out now!</h2>
                <base-button link :to="contactLink">Contact</base-button>
            </header>
            <router-view></router-view>
        </base-card>
    </section>
    <section>
        <base-card>
            <base-badge v-for="area in selectedCoache.areas" :key="area" :type="area" :title="area"></base-badge>
            <p>{{selectedCoache.description}}</p>
        </base-card>
    </section>
</div>
</template>

<script>
export default {
    props:['id'],
    data(){
        return {
            selectedCoache: null
        }
    },
    computed:{
        fullName(){
            return this.selectedCoache.firstName + " " + this.selectedCoache.lastName;
        },
        contactLink(){
            return this.$route.path + '/contact';
        }
    },
    created(){
        this.selectedCoache = this.$store.getters["coaches/coaches"].find((coach)=>coach.id===this.id);
    }
}
</script>