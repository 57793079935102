export default{
    async contactCoach(context, payload){
        const newRequest = {
            //id: new Date().toISOString(),
            //coachId: payload.coachId,
            userEmail: payload.email,
            message: payload.message
        };

        const response = await fetch(`https://coach-vue-test-default-rtdb.firebaseio.com/requests/${payload.coachId}.json`,{
            method: 'POST',
            body: JSON.stringify(newRequest)
        });

        const responseData= await response.json();
        
        console.log(responseData)
        

        newRequest.id = responseData.name;
        newRequest.coachId= payload.coachId;
        context.commit('addRequest', newRequest);
    },
    async fetchRequests(context){
        
        const userId = context.rootGetters.userId;
        const response = await fetch(`https://coach-vue-test-default-rtdb.firebaseio.com/requests/${userId}.json`);
        const responseData = await response.json();
        
        if(!response.ok){
            const error = new Error(responseData.message || 'Failed to fetch data');
            throw error;
        }

        const requests = [];

        for(const key in responseData){
            const request = {
                id: key,
                coachId: userId,
                userEmail: responseData[key].userEmail,
                message: responseData[key].message
            }
            requests.push(request);
        }
        context.commit('setRequests', requests);
    }
}